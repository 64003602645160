
import checkLoginFlow from '~/graphql/queries/sso/checkLoginFlow.gql';

export default {
  layout: 'login',
  middleware: 'guest',
  data: () => ({
    formName: 'login-form',
    form: {
      email: '',
      password: '',
      two_factor_code: '',
    },
    showLoginForm: false,
    submitting: false,
    needsTwoFactor: false,
  }),
  methods: {
    async login() {
      try {
        await this.$auth.loginWith('apollo', {data: this.form});
        const options = {
          email: this.form.email,
        };
        this.$gtm.push({event: 'authenticate', ...options});
        await this.$router.push(this.$route.query.redirect || this.localeRoute({name: 'index'}));
      } catch (err) {
        this.$formulate.handle(err, this.formName);
        if (err.graphQLErrors.find(error => error.extensions.category === '2fa') && !this.form.two_factor_code) {
          this.needsTwoFactor = true;
          this.form.two_factor_code = null;
        }
      } finally {
        this.submitting = false;
      }
    },
    async checkLoginFlow() {
      this.submitting = true;

      if (this.showLoginForm) {
        return this.login();
      }

      try {
        const {data} = await this.$apollo.query({
          query: checkLoginFlow,
          variables: {
            email: this.form.email,
          },
        });

        if (data.checkLoginFlow === 'MOODLE') {
          return this.$router.push(this.localeRoute({name: 'auth-moodle'}));
        }

        this.showLoginForm = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.submitting = false;
      }
    },
  },
};
